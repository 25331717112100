import React, { FC } from 'react'
import { Coupon } from '../../utils/coupon'
import { toMoneyWithoutCalculation as toMoney, toPercent } from '../../shared/format'

const styles = require('./BlackFridayBanner.module.scss')
const PercentageIcon = require('~assets/images/icons/percentage-icon.svg')

type Props = { coupon?: Coupon }

export const BlackFridayBanner: FC<Props> = ({ coupon }) => {
  const couponDiscount = (): string => {
    if (!coupon?.discountType) {
      return toMoney(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoney(coupon.discountAmount || 0)
    } else {
      return toPercent(coupon.discountPercentage || 0, 0)
    }
  }

  return (
    <div className={styles.content}>
      <img className={styles.icon} src={PercentageIcon} alt="Percentage Icon" />
      <div className={styles.text}>Black Friday</div>
      <div>{couponDiscount()} OFF</div>
    </div>
  )
}
